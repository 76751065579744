import { useMemo } from 'react'
import {
  BellAlertIcon,
  CommandLineIcon,
  GlobeAltIcon,
  IdentificationIcon,
  FingerPrintIcon,
  LanguageIcon,
  ShieldCheckIcon,
  SwatchIcon,
  DocumentTextIcon,
  CurrencyDollarIcon,
  UserPlusIcon
} from '@heroicons/react/24/outline'
import useTranslation from '../useTranslation'

enum TypeContext {
  Profile = 'profile',
  Account = 'account',
  Billing = 'billing'
}

type NavigationItem = {
  name: string
  href: string
  icon: any
  current: boolean
  hidden?: boolean
}

type UseProfileResponse = {
  navigation: NavigationItem[]
  breadcrumbs: {
    name: string
    href: string | null
    current: boolean
  }[]
}

const useNavigationContext = (pathname: string): UseProfileResponse => {
  const { t } = useTranslation(
    ['sidebarProfile', 'sidebarAccount', 'sidebarBilling'],
    true
  )

  const typeContext = useMemo(() => {
    if (pathname.includes('/profile')) return TypeContext.Profile
    if (pathname.includes('/account')) return TypeContext.Account
    if (pathname.includes('/billing')) return TypeContext.Billing
    return null
  }, [pathname])

  const navigation = useMemo(() => {
    const navigationProfile = [
      {
        name: t?.general,
        href: '/profile',
        icon: IdentificationIcon,
        current: pathname === '/profile'
      },
      {
        name: t?.pendingInvitations,
        href: '/profile/pending-invitations',
        icon: UserPlusIcon,
        current: pathname.includes('/profile/pending-invitations')
      },
      {
        name: t?.changePassword,
        href: '/profile/change-password',
        icon: FingerPrintIcon,
        current: pathname.includes('/profile/change-password')
      },
      {
        name: t?.socialLogins,
        href: '/profile/social-logins',
        icon: GlobeAltIcon,
        current: pathname.includes('/profile/social-logins'),
        hidden: true
      },
      {
        name: t?.signatures,
        href: '/profile/signatures',
        icon: LanguageIcon,
        current: pathname.includes('/profile/signatures')
      },
      {
        name: t?.notifications,
        href: '/profile/notifications',
        icon: BellAlertIcon,
        current: pathname.includes('/profile/notifications')
      },
      {
        name: t?.digitalCertificates,
        href: '/profile/digital-certificates',
        icon: ShieldCheckIcon,
        current: pathname.includes('/profile/digital-certificates'),
        hidden: true
      },
      {
        name: t?.apiKeys,
        href: '/profile/api-keys',
        icon: CommandLineIcon,
        current: pathname.includes('/profile/api-keys'),
        hidden: true
      },
      {
        name: t?.integrations,
        href: '/profile/integrations',
        icon: SwatchIcon,
        current: pathname.includes('/profile/integrations'),
        hidden: true
      }
    ] as NavigationItem[]

    const navigationAccount = [
      {
        name: t?.general,
        href: '/account',
        icon: IdentificationIcon,
        current: pathname === '/account',
        hidden: true
      },
      {
        name: t?.notifications,
        href: '/account/notifications',
        icon: BellAlertIcon,
        current: pathname.includes('/account/notifications')
      },
      {
        name: t?.whiteLabel,
        href: '/account/white-label',
        icon: SwatchIcon,
        current: pathname.includes('/account/white-label'),
        hidden: true
      },
      {
        name: t?.badge,
        href: '/account/badge',
        icon: SwatchIcon,
        current: pathname.includes('/account/badge'),
        hidden: true
      }
    ] as NavigationItem[]

    const navigationBilling = [
      {
        name: t?.plans,
        href: '/billing/plans',
        icon: DocumentTextIcon,
        current: pathname.includes('/billing/plans')
      },
      {
        name: t?.payments,
        href: '/billing/payments',
        icon: CurrencyDollarIcon,
        current: pathname.includes('/billing/payments')
      },
      {
        name: t?.taxData,
        href: '/billing/tax-data',
        icon: ShieldCheckIcon,
        current: pathname.includes('/billing/tax-data')
      }
    ] as NavigationItem[]

    const navigation =
      typeContext === TypeContext.Profile
        ? navigationProfile
        : typeContext === TypeContext.Account
          ? navigationAccount
          : navigationBilling

    return navigation?.filter((item) => !item.hidden)
  }, [
    t?.general,
    t?.pendingInvitations,
    t?.changePassword,
    t?.socialLogins,
    t?.signatures,
    t?.notifications,
    t?.digitalCertificates,
    t?.apiKeys,
    t?.integrations,
    t?.whiteLabel,
    t?.badge,
    t?.plans,
    t?.payments,
    t?.taxData,
    pathname,
    typeContext
  ])

  const breadcrumbs = useMemo(() => {
    const isBasePath =
      pathname.endsWith('/profile') ||
      pathname.endsWith('/account') ||
      pathname.endsWith('/billing')
    const baseCrumb = {
      name:
        typeContext === TypeContext.Profile
          ? t?.profile
          : typeContext === TypeContext.Account
            ? t?.account
            : t?.billing,
      href:
        typeContext === TypeContext.Profile
          ? '/profile'
          : typeContext === TypeContext.Account
            ? '/account'
            : null,
      current: isBasePath
    }
    const activeItem = navigation.find((item) => item.current)

    const isSettingsPath =
      pathname.includes('/profile') ||
      pathname.includes('/account') ||
      pathname.includes('/billing')

    return [
      ...(isSettingsPath
        ? [{ name: t?.settings, href: null, current: false }]
        : []),
      baseCrumb,
      ...(activeItem
        ? [
            {
              name: activeItem.name,
              href: activeItem.href,
              current: true
            }
          ]
        : [])
    ]
  }, [navigation, pathname, typeContext, t])

  return {
    navigation,
    breadcrumbs
  }
}

export default useNavigationContext
