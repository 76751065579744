import React from 'react'
import { useReviewEnvelope } from '@/providers/ReviewEnvelope'
import { HeaderEnvelope } from '@/organisms/index'
import { cn } from '@/lib/utils'

interface Props {
  children: React.ReactNode
  mode: 'review' | 'view'
  className?: string
  isSandboxEnvelope?: boolean | null
}

const PublicEnvelopeTemplate: React.FC<Props> = ({
  children,
  mode,
  className,
  isSandboxEnvelope
}) => {
  const { isSandboxEnvelope: isSandboxReview } = useReviewEnvelope()

  const showSandboxAlertBottom = Boolean(isSandboxReview || isSandboxEnvelope)

  return (
    <div className="min-h-screen bg-gray-100">
      <HeaderEnvelope
        mode={mode}
        showSandboxAlertBottom={showSandboxAlertBottom}
      />
      <main className={cn('relative min-w-full h-full', className)}>
        {children}
      </main>
    </div>
  )
}

export default PublicEnvelopeTemplate
