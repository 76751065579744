import React from 'react'
import { cn } from '@/lib/utils'
import { Typography } from '@/ui/atoms'

type Props = {
  title: string
  description?: string
  showSkeleton?: boolean
  className?: string
  children?: React.ReactNode
}

const SettingsTemplate: React.FC<Props> = ({
  title,
  description,
  showSkeleton,
  className,
  children
}) => {
  return (
    <div className="flex flex-col w-full h-full max-w-5xl min-h-full gap-4">
      <div className="flex flex-col gap-1">
        <Typography
          variant="title-2xl-medium"
          className="text-gray-700 dark:text-gray-700"
          showSkeleton={showSkeleton}
          type="h1"
        >
          {title}
        </Typography>
        {(description || showSkeleton) && (
          <Typography
            variant="text-sm-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={showSkeleton}
            type="p"
          >
            {description}
          </Typography>
        )}
      </div>
      <div
        className={cn(
          'flex flex-col h-full gap-6 p-4 md:p-6 bg-white rounded-lg shadow dark:bg-gray-100 dark:text-black w-full sm:w-[500px]',
          className
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default SettingsTemplate
